let mixin = {
  methods: {
    formatDate(cellValue) {
      if (cellValue == null || cellValue == "") return "";
      var date = new Date(cellValue);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    getDateStr(now) {
      var year = now.getFullYear(); // 年
      var month = now.getMonth() + 1; // 月
      var day = now.getDate(); // 日
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month + "-" + day;
    },
    handleImageError(img) {
      if (
        img &&
        (img.indexOf("https://") != -1 || img.indexOf("http://") != -1)
      ) {
        return img;
      } else {
        return "https://yygpro-oss.oss-cn-shenzhen.aliyuncs.com/images/notgoodspic.png";
      }
    },
    formatNumberWithCommas(number) {
      return number.toLocaleString("en-US");
    },
    formatDaer(cellValue) {
      if (cellValue == null || cellValue == "") return "";
      var date = new Date(cellValue);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return year + "-" + month + "-" + day;
    },
  },
};

export default mixin;
