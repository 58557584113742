
import { phpBaseUrl } from "../utils/anyUrl.js";
var BaseUrl = phpBaseUrl;
export const wholeUrl = BaseUrl;

// 图片上传地址
export const imgUploadUrl = wholeUrl + "/api/basis/upload";
// 导入物流公司地址
export const leadLogisticsUrl = wholeUrl + "/config/logistics/import";
// 登录
export const login = BaseUrl + "/login";
// 首页
export const index = BaseUrl + "/index/index";
// 权限请求
export const menuPower = BaseUrl + "/menu"; //拿取菜单列表
export const menuList = BaseUrl + "/Auth/menuList"; //拿取权限里的菜单列表
export const AddMenu = BaseUrl + "/Auth/addMenu"; //新建菜单
export const handleMenu = BaseUrl + "/Auth/handleMenu"; //修改菜单
export const bottonList = BaseUrl + "/Auth/bottonList"; //获取按钮列表
export const addBotton = BaseUrl + "/Auth/addBotton"; //新增按钮
export const handleBotton = BaseUrl + "/Auth/handleBotton"; //修改按钮
// export const roleList = BaseUrl + '/Auth/roleList' //获取角色列表
export const roleList = BaseUrl + "/role/list"; //获取角色列表
export const CanDistributionRole = BaseUrl + "/Admin/CanDistributionRole"; //获取当前账号可分配的权限
export const edit = BaseUrl + "/Admin/edit"; //编辑
export const AdminDelete = BaseUrl + "/Admin/delete"; //编辑
export const v2register = BaseUrl + "/Admin/register"; //创建账号
export const adminList = BaseUrl + "/Admin/adminList"; //获取后台人员列表
// export const allAuth = BaseUrl + '/Auth/allAuth' //获取后台所有权限
export const allAuth = BaseUrl + "/permissions/menu"; //获取后台所有权限
export const consultRole = BaseUrl + "/permissions/consult_role"; //获取当前账户可查看的参考角色
export const adminMenuIds = BaseUrl + "/permissions/role_menu_ids"; //获取指定角色菜单id集合
export const roleHandle = BaseUrl + "/Auth/roleHandle"; //获取后台所有权限
export const history = BaseUrl + "/Auth/history"; //获取后台所有权限
export const register = BaseUrl + "/Admin/register"; //新建编辑后台人员
export const allBotton = BaseUrl + "/button"; //获取所有已选按钮权
export const unitType = BaseUrl + "/User/unitType/list"; //单位所有注册类型
export const createRole = BaseUrl + "/role/create_role"; //创建角色
export const roleEdit = BaseUrl + "/role/edit"; //编辑角色
export const roleInfo = BaseUrl + "/role/info"; //角色详情
export const roleAdmin = BaseUrl + "/role/role_admin"; //获取角色下管理员账号
export const roleDel = BaseUrl + "/role/del"; //删除角色
export const roleTransfer = BaseUrl + "/role/transfer"; //转移角色下账号
export const selectPlatformStaff =
  BaseUrl + "/MechanismStaff/selectPlatformStaff"; //获取员工列表
export const selectPlatformMechanism =
  BaseUrl + "/MechanismStaff/selectPlatformMechanism"; //获取机构列表
export const addPlatformMechanism =
  BaseUrl + "/MechanismStaff/addPlatformMechanism"; //新增机构
export const deleteMechanism = BaseUrl + "/MechanismStaff/deleteMechanism"; //新增机构
export const renamingPlatform = BaseUrl + "/MechanismStaff/renamingPlatform"; //机构重名命
export const movePlatform = BaseUrl + "/MechanismStaff/movePlatform"; //移动机构
export const allAdmin = BaseUrl + "/Admin/allAdmin"; //获取所有下级管理员包括自己
export const addPlatformStaff = BaseUrl + "/MechanismStaff/addPlatformStaff"; //创建员工
export const selectDetailsStaff =
  BaseUrl + "/MechanismStaff/selectDetailsStaff"; //查询员工详情
export const updatePlatformStaff =
  BaseUrl + "/MechanismStaff/updatePlatformStaff"; //编辑员工
export const disableEnable = BaseUrl + "/MechanismStaff/disableEnable"; //禁用/启用员工
export const deleteStaff = BaseUrl + "/MechanismStaff/deleteStaff"; //删除员工
export const platformMenuList = BaseUrl + '/platformMenu/list' //新平台菜单-列表
export const platformMenuAdd = BaseUrl + '/platformMenu/add' //新平台菜单-新建菜单
export const platformMenuAddEdit = BaseUrl + '/platformMenu/edit' //新平台菜单-编辑菜单
export const platformMenuAddSwitchHidden = BaseUrl + '/platformMenu/switchHidden' //新平台菜单-显示/隐藏
export const platformMenuAddDel = BaseUrl + '/platformMenu/del' //新平台菜单-删除

export const supplyChainMenuList = BaseUrl + '/supplyChainMenu/list' //新供应链菜单-列表
export const supplyChainMenuAdd = BaseUrl + '/supplyChainMenu/add' //新供应链菜单-新建菜单
export const supplyChainMenuEdit = BaseUrl + '/supplyChainMenu/edit' //新供应链菜单-编辑菜单
export const supplyChainMenuSwitchHidden = BaseUrl + '/supplyChainMenu/switchHidden' //新平台菜单-显示/隐藏
export const supplyChainMenuDel = BaseUrl + '/supplyChainMenu/del' //新供应链菜单-删除

//公共路由
export const comSearchList = BaseUrl + "/config/district/listTree"; //
export const basisscope = BaseUrl + "/basis/scope"; //经营类别选择器
// 基础设置
export const factorylist = BaseUrl + "/config/factory/list"; //厂家证明列表
export const factoryedit = BaseUrl + "/config/factory/edit"; //厂家新建编辑
export const factoryadd = BaseUrl + "/config/factory/add"; //新建
export const factorydelete = BaseUrl + "/config/factory/delete"; // 删除
export const resetPassword = BaseUrl + "/Auth/resetPassword"; // 修改密码

// 物流公司
export const logisticslist = BaseUrl + "/config/logistics/list"; //物流公司列表
export const logisticsadd = BaseUrl + "/config/logistics/add"; //新增物流
export const logisticsedit = BaseUrl + "/config/logistics/edit"; //编辑物流
// 平台分佣
export const loanCommissionlist = BaseUrl + "/config/loanCommission/list"; //免息白条配置列表
export const loanCommissionsave = BaseUrl + "/config/loanCommission/save"; //保存平台分佣配置
// 协议中心
export const agreementlist = BaseUrl + "/agreement/list"; //协议中心
export const agreementeditInfo = BaseUrl + "/agreement/editInfo"; // 保存
// 供应商入驻
export const addbusinessQualification =
  BaseUrl + "/v2/businessQualification/type/add"; //添加企业类型
export const businessQualificationTypeList =
  BaseUrl + "/v2/businessQualification/type/list"; //企业类型列表
export const addGetLastCode =
  BaseUrl + "/v2/businessQualification/type/getLastCode"; //新增企业类型
export const businessQualificationTypeedit =
  BaseUrl + "/v2/businessQualification/type/edit"; //编辑企业类型get为获取详情
export const templateList =
  BaseUrl + "/businessQualification/type/templateList"; //获取商户资质列表
export const changeSelect =
  BaseUrl + "/businessQualification/type/changeSelect"; //资质开关
export const Qualificationlist =
  BaseUrl + "/v2/businessQualification/template/list"; //资质列表
export const Qualificationadd =
  BaseUrl + "/v2/businessQualification/template/add   "; //新增资质配置项
export const configList =
  BaseUrl + "/v2/businessQualification/template/configList"; //资质配置列表
export const configSet =
  BaseUrl + "/v2/businessQualification/template/configSet"; //资质配置列表
export const templateedit = BaseUrl + "/v2/businessQualification/template/edit"; //编辑资质
// 会员入住
export const memberLibrarycreate =
  BaseUrl + "/userQualification/memberLibrary/create"; //新增资质库
export const memberLibrarylist =
  BaseUrl + "/v2/userQualification/template/list"; //用户资质库列表
export const memberTypeLibrarylist =
  BaseUrl + "/userQualification/memberTypeLibrary/list"; //编辑资质
export const memberTypeLibraryedit =
  BaseUrl + "/v2/userQualification/template/edit"; //用户资质库编辑
export const memberTypeLibraryset =
  BaseUrl + "/userQualification/memberTypeLibrary/set"; //配置会员资质开关
export const memberTypelist = BaseUrl + "/v2/userQualification/type/list"; //会员资质类型列表
export const addressMsg = BaseUrl + "/userQualification/memberAudit/addressMsg"; //收货地址详情
export const scopeBusiness =
  BaseUrl + "/userQualification/memberAudit/scopeBusiness"; //会员的经营范围详情
export const qualificationGrouplist =
  BaseUrl + "/v2/userQualification/qualificationGroup/list"; //会员资质类型列表
export const qualificationGroupset =
  BaseUrl + "/v2/userQualification/qualificationGroup/set"; //配置会员所需资质
export const businessqualificationGrouplist =
  BaseUrl + "/v2/businessQualification/qualificationGroup/list"; //供应商三证资质类型列表
export const businessqualificationGroupset =
  BaseUrl + "/v2/businessQualification/qualificationGroup/set"; //供应商三证所需资质
export const businessgetLastCode =
  BaseUrl + "/v2/businessQualification/template/getLastCode"; //供应商资质获取编码

// 经营类别
export const scopelist = BaseUrl + "/scope/type/list"; //经营类别列表
export const scopegoodsQualify = BaseUrl + "/scope/goodsQualify/list"; //资质库列表
export const scopeadd = BaseUrl + "/scope/type/add"; // 添加经营类别
export const goodsQualifyAdd = BaseUrl + "/scope/goodsQualify/add"; // 添加商品资质
export const goodsQualifyEdit = BaseUrl + "/scope/goodsQualify/edit"; // 编辑商品资质

// 审核中心
export const reviewList = BaseUrl + "/businessQualification/review/list"; //供应商审核列表
export const reviewInfo = BaseUrl + "/businessQualification/review/info"; //供应商审核详情
// export const reviewstep = BaseUrl + "/businessQualification/review/step"//设置审核步骤条
export const reviewstep = BaseUrl + "/v2/businessAudit/audit/setStep"; //设置审核步骤条
export const reviewchangeSelect =
  BaseUrl + "/businessQualification/review/changeSelect"; //设置经营范围
export const scopeConfig = BaseUrl + "/v2/businessAudit/audit/scopeConfig"; //新设置经营范围
export const reviewsetLoan = BaseUrl + "/businessQualification/review/setLoan"; //设置白条
export const reviewsetDistrict =
  BaseUrl + "/businessQualification/review/setDistrict"; //设置地区
export const memberAuditlist = BaseUrl + "/userQualification/memberAudit/list"; //会员审核列表
export const memberAudituserDetail =
  BaseUrl + "/userQualification/memberAudit/userDetail"; //会员基础资料
// export const memberAudituserqualify = BaseUrl + "/userQualification/memberAudit/qualify"//
export const memberAudituserqualify = BaseUrl + "/v2/userAudit/audit/qualify"; //
// export const memberAuditaudit = BaseUrl + "/userQualification/memberAudit/audit"//会员审核
export const memberAuditaudit = BaseUrl + "/v2/userAudit/audit/audit"; //会员审核
export const memberAuditstatusLine = BaseUrl + "/v2/userAudit/audit/statusLine"; //时间线
export const memberAuditunit = BaseUrl + "/userQualification/memberAudit/unit"; //单位信息详情
export const memberAuditaddressMsg =
  BaseUrl + "/userQualification/memberAudit/addressMsg"; //收货信息
// export const memberAuditsavesLine = BaseUrl + "/userQualification/memberAudit/savesLine"//审核时间线
export const memberAuditsavesLine = BaseUrl + "/v2/userAudit/audit/savesLine"; //审核时间线
export const savesLine = BaseUrl + "/v2/userAudit/audit/savesLine"; //审核时间线 首营版本JAVA转PHP
export const memberAuditscopeBusiness =
  BaseUrl + "/userQualification/memberAudit/scopeBusiness"; //获取经营范围
export const memberAuditsaveScopeBusiness =
  BaseUrl + "/userQualification/memberAudit/saveScopeBusiness"; //保存经营范围
export const v2memberAuditsaveScopeBusiness = BaseUrl + "/User/saveScope"; //保存经营范围
export const memberAuditsavesaveUserInfo =
  BaseUrl + "/userQualification/memberAudit/saveUserInfo"; //保存会员信息

export const saveUserInfo = BaseUrl + "/v2/userAudit/audit/saveUserInfo"; //保存会员信息 首营版本JAVA转PHP
export const memberAuditsavelist = BaseUrl + "/goodsAudit/goodsAudit/list"; //商品审核
export const goodsAuditbusinessDetail =
  BaseUrl + "/goodsAudit/goodsAudit/businessDetail"; //商品审核详情
export const getscopeBusiness = BaseUrl + "/userAudit/scopeBusiness"; //会员审核第三步替换Java接口获取经营范围

export const goodsAudit = BaseUrl + "/goodsAudit/goodsAudit/audit"; //商品审核

export const platformGoodsis_audit = BaseUrl + "/goods/platformGoods/is_audit"; //是否审核资料库
export const goodsAudittimeLine = BaseUrl + "/goodsAudit/goodsAudit/timeLine"; //商品审核时间线
export const goodsAuditsaveTimeLine =
  BaseUrl + "/goodsAudit/goodsAudit/saveTimeLine"; //更改时间线//
// export const memberAuditqualifyDown = BaseUrl + "/userQualification/memberAudit/qualifyDown"//会员资质下载
export const memberAuditqualifyDown =
  BaseUrl + "/v2/userQualification/downloadPdf"; //会员资质下载
export const downloadReview = BaseUrl + "/v2/userQualification/downloadReview"; //会员审核资质下载
export const activityReview = BaseUrl + "/activity/review/review"; //审核活动商品
export const activityReviewList = BaseUrl + "/activity/review/list"; //审核活动商品列表
export const activityReviewInfo = BaseUrl + "/activity/review/info"; //审核商品详情
export const UserQualificationMemberAuditReviewList =
  BaseUrl + "/userQualification/memberAudit/reviewList"; //审核会员列表
export const memberAuditUserInfo =
  BaseUrl + "/userQualification/memberAudit/userInfo"; //会员信息详情
export const memberAuditReviewUserInfo =
  BaseUrl + "/userQualification/memberAudit/reviewUserInfo"; //提交会员审核
export const logList = BaseUrl + "/businessQualification/review/log/list"; //供应商审核列表
export const userQualificationGetLastCode =
  BaseUrl + "/v2/userQualification/template/getLastCode"; //添加资质会员配置获取生成编码
export const userQualificationAdd =
  BaseUrl + "/v2/userQualification/template/add"; //添加资质会员配置
export const complainsRecords = BaseUrl + "/business/commission/complaintList"; //获取查询投诉记录
export const afficheList = BaseUrl + "/affiche/list"; //获取公告列表
export const afficheDetail = BaseUrl + "/affiche/detail"; //获取公告详情
export const afficheAudit = BaseUrl + "/affiche/audit"; //公告审核
export const goodsImportAudit = BaseUrl + "/goods/platformGoods/goodsImportAudit"; //商品资料库-商品导入文件审核
//goodsAudit/goodsAudit/saveTimeLine

export const goodsAuditeditMerchant =
  BaseUrl + "/goodsAudit/goodsAudit/editMerchant"; //会员审核
// 商品接口
export const platformGoodsdetail = BaseUrl + "/goods/platformGoods/detail"; //商品资料详情
export const platformGoodsedit = BaseUrl + "/goods/platformGoods/edit"; //商品资料库编辑保存
export const getAlarm = BaseUrl + "/business/getAlarm"; //获取预警配置
export const setAlarm = BaseUrl + "/business/setAlarm"; //设置预警配置
export const platformGoodsapplyEdit =
  BaseUrl + "/goods/platformGoods/applyEdit"; //新商品资料库编辑保存
export const platformGoodsEdit = BaseUrl + "/goods/platformGoods/edit"; //新商品资料库编辑保存(new)
export const goodsAuditaudit = BaseUrl + "/goodsAudit/goodsAudit/audit"; //供应商审核接口
export const hasGoodsByBarCode =
  BaseUrl + "/goods/platformGoods/hasGoodsByBarCode"; //通过69码查询平台商品是否存在
export const platformGoodscheck = BaseUrl + "/goods/platformGoods/check"; // 判断商品资料库商品是否存在
export const categorylist = BaseUrl + "/goods/category/list"; //商品分类列表
export const categoryadd = BaseUrl + "/goods/category/add"; //商品分类新增
export const categorycategory = BaseUrl + "/goods/category/category"; //腹肌菜单列表
export const categoryedit = BaseUrl + "/goods/category/edit"; //商品分类详情编辑
export const platformGoodslist = BaseUrl + "/goods/platformGoods/list"; //商品资料库列表
export const goodsInfolist = BaseUrl + "/goods/goodsInfo/list"; //云仓商品库
export const platformGoodsexport = BaseUrl + "/goods/platformGoods/export"; //商品资料库列表到处
export const goodsInfodetail = BaseUrl + "/goods/goodsInfo/detail"; //云仓商品库详情
export const baseGoodslist = BaseUrl + "/config/baseGoods/list"; //兜底数据列表
export const categorydelete = BaseUrl + "/goods/category/delete"; //删除分类
export const categorycommission = BaseUrl + "/goods/goodsInfo/commission"; // 推奥珍贵平台
export const goodsAuditdistrict = BaseUrl + "/goodsAudit/goodsAudit/district"; //获取销售商圈
export const unclassified = BaseUrl + "/goods/goodsInfo/export"; //获取销售商圈
export const transferGoods = BaseUrl + "/goods/category/transferGoods"; //转移商品
export const getAllGoodsCount = BaseUrl + "/goods/category/getAllGoodsCount"; //转移商品数量
export const getCategoryById = BaseUrl + "/goods/category/getCategoryById"; //根据二维数组分类id查询分类
export const getGoodsImportRecordList = BaseUrl + "/goods/platformGoods/getGoodsImportRecordList"; //商品资料库-商品导入记录
export const getPlatformGoodsSupplier = BaseUrl + "/goods/platformGoods/getPlatformGoodsSupplier"; //商品资料库-获取使用商品资料的供应商
export const deletePlatformGoods = BaseUrl + "/goods/platformGoods/deletePlatformGoods"; //商品资料库-删除资料库商品
export const syncSupplierGoods = BaseUrl + "/goods/platformGoods/syncSupplierGoods"; //商品资料库-更新供应商商品资料


// 会员中心
export const refreshAuditStatus = BaseUrl + "/userAudit/refreshAuditStatus" //刷新进度
export const getQualifyEffective = BaseUrl + "/userAudit/getQualifyEffective" //获取审核状态资质效期
export const modifyAccount = BaseUrl + "/User/modifyAccount" //修改账户
export const getMultipleAccounts = BaseUrl + "/User/getMultipleAccounts" //编辑-多店/账号列表
export const resetAccountPassword = BaseUrl + "/User/resetAccountPassword" //编辑-多店/账户列表-重置密码
export const changeAccountMobile = BaseUrl + "/User/changeAccountMobile" //编辑-多店/账号列表-修改手机号
export const getAccountShops = BaseUrl + "/User/getAccountShops" //编辑-多店/账号列表-绑定店铺列表
export const getMobileChangeLog = BaseUrl + "/User/getMobileChangeLog" //编辑-多店/账户列表-手机号更换记录
export const getUserInfoDetail = BaseUrl + "/User/getUserInfoDetail" //编辑-多店/账号列表-绑定店铺列表
export const updateUserInfo = BaseUrl + "/User/updateUserInfo" //编辑-多店/账号列表-绑定店铺列表
export const userSyncLog = BaseUrl + "/User/userSyncLog" //会员列表-详情-用户同步更新日志列表
export const updateUserInvoice = BaseUrl + "/User/updateUserInvoice" //会员列表-编辑会员发票信息
export const getUserInvoice = BaseUrl + "/User/getUserInvoice" //会员列表-获取会员发票信息
// 用户类型列表
export const userTypeList = BaseUrl + "/User/userType/list";
// 用户列表
export const userList = BaseUrl + "/User/list";
// 编辑会员详情(get为获取)
export const userEdit = BaseUrl + "/User/edit";
// 会员资质详情
export const qualifyDetail = BaseUrl + "/User/qualifyDetail";
// 会员业务员关系
export const getSalesman = BaseUrl + "/User/salesman";
export const UsersaveAddress = BaseUrl + "/User/saveAddress"; //修改收货地址
export const switchMemberStatus = BaseUrl + "/user/switchMemberStatus"; //切换会员状态


// 订单中心接口
export const orderlist = BaseUrl + "/order/orderCenter/list"; //获取订单列表
export const ordertimeLine = BaseUrl + "/order/orderCenter/timeLine"; //订单时间线
export const orderdetailGoodsList =
  BaseUrl + "/order/orderCenter/detailGoodsList"; //订单商品列表
export const orderdetailBasis = BaseUrl + "/order/orderCenter/detailBasis"; // 订单基础信息
export const orderCenterdetailCourier =
  BaseUrl + "/order/orderCenter/detailCourier"; //订单物流
export const orderdetail = BaseUrl + "/order/certification/detail"; //订单擦看证件
export const orderqualification =
  BaseUrl + "/order/certification/qualification"; //证件资质
export const orderdownPdf = BaseUrl + "/order/certification/downPdf"; //下载资质
export const downloadUserInfo =
  BaseUrl + "/v2/userQualification/downloadUserInfo"; //下载资质
export const basistime = BaseUrl + "/basis/time"; //审核时间借口
export const getExportKey = BaseUrl + "/order/orderCenter/getExportKey"; //导出订单列表密钥
export const getexportOrder = BaseUrl + "/orderCenter/exportOrder"; //导出订单列表密钥
export const cancelOrder = BaseUrl + "/orderCenter/cancelOrder"; //取消订单
export const getPaymentTypeList = BaseUrl + "/paymentType/all"; //前端获取支付方式列表
export const orderOutbound = BaseUrl + "/orderCenter/order/orderOutbound"; //订单中心-获取出库单
export const getSingleAccompanying = BaseUrl + "/order/getSingleAccompanying"; //通过单个出库单号查询随货同行单
export const downloadOrderOutbound = BaseUrl + "/orderCenter/order/downloadOrderOutbound"; // 订单详情-下载随货同行单
export const orderAlertconfigList = BaseUrl + "/config/orderAlertConfig/configList"; // 预警配置-配置列表
export const configDetail = BaseUrl + "/config/orderAlertConfig/configDetail"; // 预警配置-详情
export const configEdit = BaseUrl + "/config/orderAlertConfig/configEdit"; //预警配置-编辑
export const batchEdit = BaseUrl + "/config/orderAlertConfig/configBatchEdit"; // 预警配置-批量编辑
export const searchList = BaseUrl + "/order/orderAlertRecord/searchList"; // 订单预警-列表
export const exportRecord = BaseUrl + "/order/orderAlertRecord/exportRecord"; // 订单预警-导出
export const nodeSelect = BaseUrl + "/order/orderAlertRecord/nodeSelect"; // 预警节点下拉选择
export const orderApplicationList = BaseUrl + "/orderApplication/list" //自动请货申请单-列表
export const businessSearch = BaseUrl + "/business/businessSearch" //供应商搜索
export const orderApplicationDetails = BaseUrl + "/orderApplication/detail" //自动请货申请单-详情
export const orderApplicationItemList = BaseUrl + "/orderApplication/itemList" //自动请货申请单-详情（品种数据）列表

//售后订单接口
export const afterSalesOrderListPost = BaseUrl + "/order/after_sales/list"; //获取售后订单列表
export const searchCriteriaGet = BaseUrl + "/order/after_sales/searchEnum"; //获取下拉框数据
export const platforminterventionPost =
  BaseUrl + "/order/after_sales/intervention"; //平台介入
export const editGetOrPost = BaseUrl + "/order/after_sales/edit"; //编辑（get为获取）

// 基础设置
export const districtList = BaseUrl + "/config/district/list"; //获取地区
export const districtadd = BaseUrl + "/config/district/add"; //新增地区
export const districtedit = BaseUrl + "/config/district/edit"; //编辑地区
export const batchTransfer = BaseUrl + "/scope/type/batchTransfer"; //批量转移
export const goodsQualifylist = BaseUrl + "/scope/type/configList"; //商品配置资质列表
export const goodsQualifychangeSelect =
  BaseUrl + "/scope/goodsQualify/changeSelect"; //商品资质选择
export const typedelete = BaseUrl + "/scope/type/delete"; //删除经营类别
export const typeedit = BaseUrl + "/scope/type/edit"; //编辑经营类别
export const typeSelectList = BaseUrl + "/scope/type/selectList"; //编辑经营类别
export const goodsQualifyDelte = BaseUrl + "/scope/goodsQualify/delete"; //删除资质
export const configurationlist = BaseUrl + "/config/configuration/list"; // 全局配置
export const baseConfigedit = BaseUrl + "/config/configuration/edit"; //编辑配置
export const logisticsdele = BaseUrl + "/config/logistics/delete"; //删除物流
export const downloadTemplate = BaseUrl + "/config/logistics/downloadTemplate"; //删除物流
export const getPlatformIOU = BaseUrl + "/business/commission/getPlatformIOU"; //平台白条分佣规则查询
export const returnTimelineslist =
  BaseUrl + "/config/configuration/returnTimelines/list"; //全局配置-售后配置-退货时效-白名单-列表
export const returnTimelinesadd =
  BaseUrl + "/config/configuration/returnTimelines/add"; //全局配置-售后配置-退货时效-白名单-添加
export const returnTimelinesremove =
  BaseUrl + "/config/configuration/returnTimelines/remove"; //全局配置-售后配置-退货时效-白名单-移除
export const receiveTimelineslist =
  BaseUrl + "/config/configuration/receiveTimelines/list"; //全局配置-售后配置-收货时效-白名单-列表
export const receiveTimelinesadd =
  BaseUrl + "/config/configuration/receiveTimelines/add"; //全局配置-售后配置-收货时效-白名单-添加
export const receiveTimelinesremove =
  BaseUrl + "/config/configuration/receiveTimelines/remove"; //全局配置-售后配置-收货时效-白名单-移除

export const updatePlatformIOU = BaseUrl + "/business/commission/updatePlatformIOU"; //平台白条分佣规则修改
export const customerServiceList = BaseUrl + "/config/configuration/customerServiceList"; //全局配置-客服电话配置-列表
export const createCustomerService = BaseUrl + "/config/configuration/createCustomerService"; //全局配置-客服电话-新增
export const updateCustomerService = BaseUrl + "/config/configuration/updateCustomerService"; //全局配置-客服电话-更新
export const customerServiceStatus = BaseUrl + "/config/configuration/customerServiceStatus"; //全局配置-客服电话-启停用
// 供应商中心
export const getBusinessList = BaseUrl + "/business/list"; //供应商列表
export const getBusinessInfo = BaseUrl + "/businessQualification/review/info"; //供应商详情
export const qualificationExamineList = BaseUrl + "/businessQualification/review/qualificationExamineList"; //往期资质
export const getCredentials = BaseUrl + "/businessQualification/review/getCredentials"; //审核记录详情（资质详情）
export const getBusinessScopeList = BaseUrl + "/business/scopeList"; //可售种类列表 (经营类型)
export const getDistrictList = BaseUrl + "/business/getDistrictList"; //(可经营地区列表)
export const changeBusinessSelect = BaseUrl + "/business/changeSelect"; //(可经营地区列表)
export const businessEdit = BaseUrl + "/business/edit"; //(编辑) 供应商启用/停用
export const reviewSetLoan = BaseUrl + "/businessQualification/review/setLoan"; //设置白条分期
export const businessgetReturnAddress = BaseUrl + "/business/getReturnAddress"; //
export const business_ledgerlist = BaseUrl + "/business_ledger/list"; //分账列表
export const business_ledgeredit = BaseUrl + "/business_ledger/edit"; //编辑分账
export const business_ledgeradd = BaseUrl + "/business_ledger/add"; //新建分账
export const business_ledgerdelete = BaseUrl + "/business_ledger/delete"; // 删除分账
export const baseGoodsadd = BaseUrl + "/config/baseGoods/add"; //添加兜底数据
export const baseGoodsdelete = BaseUrl + "/config/baseGoods/delete"; //删除兜底数据
export const reviewgetDownLoad =
  BaseUrl + "/businessQualification/review/getDownLoad"; //下载所有数据
export const v2download = BaseUrl + "/v2/businessQualification/download"; //下载所有数据
export const v2downloadAudit = BaseUrl + "/v2/businessAudit/downloadAudit"; //下载所有数据
export const goodsInfoexport = BaseUrl + "/goods/goodsInfo/export";
export const selectIOU = BaseUrl + "/business/commission/selectIOU"; //供应商白条分佣规则查询
export const updateIOU = BaseUrl + "/business/commission/updateIOU"; //供应商白条分佣规则修改
export const getAccessConfig = BaseUrl + "/business/getAccessConfig"; //供应商ERP对接方式查询
export const updateAccessConfig = BaseUrl + "/business/updateAccessConfig"; //供应商ERP对接方式编辑
export const getRequisitionConfig = BaseUrl + "/business/getRequisitionConfig"; //【PHP】获取供应商自动请货对接信息
export const updateRequisitionConfig = BaseUrl + "/business/updateRequisitionConfig"; //【PHP】编辑保存供应商自动请货对接信息
export const requisitionConfigDetail = BaseUrl + "/business/requisitionConfigDetail"; //【PHP】供应商自动请货对接信息详情

// 结算中心
export const getBillList = BaseUrl + "/settlement/bill/list"; //台账查询
export const returnOrderList = BaseUrl + "/settlement/return_order/list"; //退款单列表
export const returnOrderInfo = BaseUrl + "/settlement/return_order/info"; //退款单详情
export const billExport = BaseUrl + "/settlement/bill/export"; //导出货款
export const billRefundExport = BaseUrl + "/settlement/bill/refundExport"; //导出退款
export const billFreightExport = BaseUrl + "/settlement/bill/freightExport"; //导出运费
export const billChargedExport = BaseUrl + "/settlement/bill/chargedExport"; //导出手续费
export const billCommissionExport =
  BaseUrl + "/settlement/bill/commissionExport"; //导出佣金
export const settlementBillListList = BaseUrl + "/settlement/billList/list"; //账单查询列表
export const settlementSubCommonList = BaseUrl + "/settlement/subCommonList"; //分佣查询
export const SettlementBillListDetail = BaseUrl + "/settlement/billList/detail"; //账单详情
export const getCount = BaseUrl + "/settlement/billList/getCount"; //佣金总计

// 金融管理
// export const userLoanUserInfo = BaseUrl + "/userLoan/userInfo/" //获取会员信息
// export const userLoanList = BaseUrl + "/userLoan/list" //获取贷款信息
// export const userLoanItem = BaseUrl + "/userLoan/item/" //还款记录
// export const userLoanUserLoanInfoList = BaseUrl + "/ptfinance/userLoan/userLoanInfoList" //平安贷贷款记录
//平台菜单
export const supplierMenuList = BaseUrl + "/supplierMenu/list"; //供应商权限菜单列表
export const supplierMenuAdd = BaseUrl + "/supplierMenu/add"; //供应商权限菜单列表增加
export const supplierMenuEdit = BaseUrl + "/supplierMenu/edit"; //供应商权限菜单编辑
export const supplierMenuDel = BaseUrl + "/supplierMenu/del"; //供应商权限菜单删除
export const supplierMenuHidden = BaseUrl + "/supplierMenu/switchHidden"; //供应商权限菜单开关
//助手App升级说明
export const appUpgradeCreate = BaseUrl + "/appUpgrade/create"; //App更新说明-新增
export const recordList = BaseUrl + "/appUpgrade/recordList"; //App更新说明-更新记录

//对账管理
export const statementWater = BaseUrl + "/orderStatement/statementWater"; //对账管理-交易流水列表
export const statementWaterExport = BaseUrl + "/orderStatement/statementWaterExport"; //对账管理-交易流水列表导出
export const orderStatementList = BaseUrl + "/orderStatement/orderStatementList"; //对账管理-订单对账单列表
export const salesStatement = BaseUrl + "/platformStatements/salesStatement"; //销售对账单列表
export const purchaseStatement = BaseUrl + "/platformStatements/purchaseStatement"; //采购对账单列表
export const commissionStatement = BaseUrl + "/platformStatements/commissionStatement"; //手续费对账单列表
export const salesSeparate = BaseUrl + "/platformStatements/salesSeparate"; //结算分账列表
export const withdrawBillcancel = BaseUrl + "/withdrawBill/cancel"; //作废账单

//系统页面
export const systemPageList = BaseUrl + "/advertisement/systemPageList"; //广告-系统页面列表
export const changeStatus = BaseUrl + "/advertisement/changeStatus"; //广告-系统页面状态更改
export const changeSort = BaseUrl + "/advertisement/changeSort"; //广告-系统页面排序更改
export const systemPageAdd = BaseUrl + "/advertisement/systemPageAdd"; //广告-系统页面新增
export const systemPageUpdate = BaseUrl + "/advertisement/systemPageUpdate"; //广告-系统页面编辑
export const systemPageDetail = BaseUrl + "/advertisement/systemPageDetail"; //广告-系统页面详情

// app更新
export const appUpgradeList = BaseUrl + "/appUpgrade/list"; //app升级列表
export const appUpgradeEdit = BaseUrl + "/appUpgrade/edit"; //app升级编辑